@import "./authorized.scss";
@import "./sidebar.scss";
@import "./chatContainer.scss";
@import "./waiting.scss";
@import "./settings.scss";
@import "./counter.scss";
@import "./attachments.scss";
@import "./video.scss";
@import "./message.scss";
@import "./from_bootstrap";
@import "./googleButton";

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-regular.eot');
    src: local('Lato Regular'),
    local('Lato-Regular'),
    url('../fonts/lato-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lato-regular.woff2') format('woff2'),
    url('../fonts/lato-regular.woff') format('woff'),
    url('../fonts/lato-regular.ttf') format('truetype'),
    url('../fonts/lato-regular.svg#lato') format('svg');
    font-weight: 400;
    font-style: normal;
}

html, body, #root, #root > div {
    height: 100%;
    font-family: Lato, Times, serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 6px;
    margin-top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #D9D9D9 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 10px;
}

.notification-dismiss {
    background: transparent !important;
    font-size: 20px !important;
}

.tutorial-popover {
    &-body {
        background-color: #ffff;
        padding: 15px;
        border-radius: 15px;
        border: 1px solid #00000052;
        font-weight: 600;
        text-align: center;
    }
}

.change-language {
    position: absolute;
    z-index: 10;
    left: 10px;
    top: 4px;

    span {
        color: #363636;
        font-weight: 500;
        cursor: pointer;
    }
}

.dropdown-language {
    * {
        cursor: pointer;
    }

    .Dropdown-control {
        padding-right: 24px;
        border: 0;
        padding-top:4px;
        padding-bottom:4px;
        background-color: transparent;
    }
}
.custom-alert {
    font-family: Arial, Helvetica, sans-serif;
    padding: 50px 100px;
    text-align: left;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;

    &-text-center {
        text-align: center;
    }

    p {
        font-size: 20px;
    }

    div {
        text-align: center;

        button {
            margin: 10px 20px 0;
            border-radius: 50px;
            padding: 5px 20px;
        }
    }
}

.manual-modal {
    width: 99%;
    height: 100%;
    background-color: #ffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    border-radius: 15px;
    margin: 0 0.5%;
    padding: 2% 15%;

    &-header {
        display: flex;
        flex-direction: row;
        margin-bottom: 25px;
        border-bottom: 1px solid #0000001c;

        p {
            width: 95%;
            font-size: 24px;
        }

        &-close {
            width: 5%;
            text-align: end;
            font-size: 20px;
            font-weight: 700;
            cursor: pointer;
            align-self: center;
        }
    }

    &-content {
        height: 90%;
        overflow-y: scroll;

        p {
            font-size: 18px;
            margin-bottom: 10px;
        }
    }

}

._container {
    width: 100%;
    padding: 30px 15px 35px;
    margin-right: auto;
    margin-left: auto;
    font-family: Lato, Verdana, serif;

    div#wave {
        position: relative;
        margin-top: 30px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        .dot {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 3px;
            background: #6347fb;
            animation: wave 1.3s linear infinite;

            &:nth-child(2) {
                animation-delay: -1.1s;
            }

            &:nth-child(3) {
                animation-delay: -0.9s;
            }
        }
    }

    @keyframes wave {
        0%, 60%, 100% {
            transform: initial;
        }

        30% {
            transform: translateY(-15px);
        }
    }

    .logged_name {
        position: absolute;
        right: 45px;
        z-index: 9;
        top: 5px;
        font-size: 14px;
        font-weight: 500;
    }

    .room {
        height: 100%;
        width: 40%;

        &-container {
            width: 95%;
            height: 100%;
        }

        &-border {
            border: 1px solid #ECECEC;
            border-radius: 19px;
            height: 100%;
        }

        &-visit {
            height: 60%;
            padding: 0 50px;

        }

        &-details {
            padding: 50px 50px;
            border-top: 1px solid #ececec;
            height: 40%;

            &-item {
                font: Bold 16px/22px Lato;
                letter-spacing: 0;
                color: #242839;

                span {
                    font: Regular 14px/17px Lato;
                    letter-spacing: 0;
                    color: #707070;
                }
            }
        }

        &-title {
            text-align: center;
            font: Bold 24px/22px Lato;
            letter-spacing: 0;
            color: #16182A;
            opacity: 1;
            margin-bottom: 50px;

            &:before {
                content: "";
                width: 30%;
                background-color: #d1d1d1;
                display: block;
                height: 2px;
                padding-top: 0px;
                margin-bottom: -15px;
            }

            &:after {
                content: "";
                width: 30%;
                background-color: #d1d1d1;
                display: block;
                height: 2px;
                padding-top: 0px;
                margin-left: 70%;
                margin-top: -10px;
            }
        }

        &-visit-title {
            text-align: left;
            font: Bold 24px/22px Lato;
            letter-spacing: 0;
            color: #242839;
            opacity: 1;
            margin-bottom: 20px;
        }

        &-visit-description {
            text-align: left;
            font: Regular 13px/22px Lato;
            letter-spacing: 0;
            color: #9F9F9F;
            opacity: 1;
        }
    }

    @media (max-width: 1199px) {
        width: 100vw !important;
        padding: 0 !important;

        .logged_name {
            position: absolute;
            top: 90%;
            right: 15px;
        }

        .clear-space-sm {
            margin: 0 !important;
            padding: 0 !important;
        }
        .row {
            width: 100vw !important;;
        }

        .room {
            margin-left: auto;
            width: 100vw;
            height: 92%;

            &-container {
                width: 100vw;
            }
        }
    }

    @media (max-width: 769px) {
        .tutorial-popover-body {
            display: none !important;
            opacity: 1;
        }
        .room {
            &-title {
                &:before {
                    display: none;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 667px) {
        .manual-modal {
            padding: 2% 5%;
            border-radius: 0;

            &-header {
                border: 0;
                margin-top: 5px;

                p {
                    margin-top: 5px;
                    font-size: 22px;
                    margin-bottom: 0;
                }

                &-close {
                    margin-right: 25px;
                }
            }

            &-content {
                h3 {
                    display: none;
                }

                p {
                    display: none;
                }
            }
        }
        .logged_name {
            display: none;
        }
        .room {
            &-visit {
                &-title {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .custom-alert {
        padding: 30px;
        text-align: center;
    }

    .tutorial-popover-body {
        display: none !important;
        opacity: 1;

    }
}

#review {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    padding: 50px;
    min-width: 400px;
    min-height: 300px;
    border-radius: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px 5px #ccc;
    text-align: center;

    .reviewTitle {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .starButton {
        font-size: 25px;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        &On {
            color: #fde00e;
        }
        &Off {
            color: #ccc;
        }
    }
    .starFooter {
        display: flex;
        justify-content: space-around;
        padding: 0 20px 5px;
        font-size: 12px;
    }

    .additional {
        text-align: left;
        padding: 10px 0;
    }

    .additionalTitle {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }

    .radioButton {
        label {
            cursor: pointer;
            user-select: none;
            font-size: 14px;
            padding-top: 10px;
        }

        [type="checkbox"] {
            width: 1.3em;
            height: 1.3em;
            background-color: white;
            border-radius: 50%;
            vertical-align: middle;
            border: 1px solid #ddd;
            appearance: none;
            -webkit-appearance: none;
            outline: none;
            cursor: pointer;
            margin-right: 10px;
        }

        [type="checkbox"]:checked {
            background-color: #fde00e;
        }

    }
    .otherIssues {
        text-align: center;
        label {
            font-size: 14px;
            font-weight: 500;
        }
        textarea {
            width: 80%;
            border-radius: 5px;
        }
    }

    .reviewFooter {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
        .reviewDismiss {
            cursor: pointer;
            background-color: transparent;
            border: none;
            color: #363636;
            padding: 6px 10px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px
        }
        .reviewSubmit {
            cursor: pointer;
            background-color: rgba(233, 84, 152, 0.65);
            border: 1px solid rgba(233, 84, 152, 0.65);
            color: #363636;
            padding: 6px 10px;
            border-radius: 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            &Disabled {
                background-color: transparent;
                border: none;
                cursor: not-allowed;
                color: rgba(54, 54, 54, 0.44);
            }
        }
    }

    .reviewTimesDismiss {
        cursor: pointer;
        background-color: transparent;
        border: none;
        color: rgba(54, 54, 54, 0.44);
        padding: 6px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        position: absolute;
        top: 10px;
        right: 10px;
    }

}
