*, *::before, *::after {
    box-sizing: border-box;
}

h1, .h1 {
    font-size: 2.5rem;
}

h2, .h2 {
    font-size: 2rem;
}

h3, .h3 {
    font-size: 1.75rem;
}

h4, .h4 {
    font-size: 1.5rem;
}

h5, .h5 {
    font-size: 1.25rem;
}

h6, .h6 {
    font-size: 1rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}


.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &-secondary {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }

    &-success {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
    }
}

$danger: #dc3545;

.mr-3 {
    margin-right: 1rem !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.text-muted {
    color: #6c757d !important;
}

.text-danger {
    color: $danger;
}

.text-center {
    text-align: center;
}

.link {
    cursor: pointer;
}

.d-flex {
    display: flex !important;
}