.sidebar-mobile {
    display: none;
}

.sidebar {
    width: 8%;
    background-color: #FFFFFF;
    text-align: left;
    font: Bold 1rem Lato;
    letter-spacing: 0;
    color: #16182A;
    opacity: 1;
    word-break: break-all;

    &-list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        background-color: #FFFFFF;
        position: relative;
        height: 100%;

        li {
            position: relative;
            display: block;
            background-color: #fff;
            padding: 20px 5px 30px;
            border: none;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            cursor: pointer;

            a {
                color: #16182A;
                text-decoration: none;
            }

            .logo {
                width: 55px;
            }

            img {
                align-self: center;
            }
        }

        &-item-help {
            position: absolute;
            bottom: 0px;
            left: 25%;
        }
    }
}

@media (max-width: 1510px) {
    .sidebar {
        font-size: 0.8rem;
    }
}

@media (max-width: 1310px) {
    .sidebar {
        font-size: 0.65rem;
    }
}


@media (max-width: 1199px) {
    .sidebar {
        display: block;
        z-index: 10;
        position: fixed;
        bottom: 0%;
        width: 100%;
        height: 7%;
        text-align: left;
        font: Bold 12px/22px Lato;
        letter-spacing: 0;
        color: #16182A;

        &-list {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            justify-content: center !important;
            flex-direction: row;
            li {
                padding: 0 35px;
                align-self: center;
                text-align: center;

                img, .logo {
                    width: 30px
                }

                a {
                    color: #16182A;
                    text-decoration: none;
                }
            }
        }
    }
}

@media (max-width: 769px) {
    .sidebar {
        padding-left: 20px;
        padding-right: 20px;

        li {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}


@media (max-width: 667px) {
    .sidebar {
        padding-left: 0px;
        padding-right: 0;
        bottom: 2%;

        .logo, .qrcode {
            display: none;
        }

        li {
            padding-left: 15px;
            padding-right: 15px;
            margin: 0;
            img {
                width:40px;
            }
            span {
                display: none;
            }
        }
    }
}

@media (max-width: 390px) {
    .sidebar {
        height:7.5%;
        padding-left: 10px;
        padding-right: 10px;
        li {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

@media (max-height: 1024px) {
    .sidebar {
        height: 7%;
    }
}

@media (max-height: 667px) {
    .sidebar {
        height: 7%;
    }
}

