.chat-container {
    height: 100%;
    width: 92%;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 1199px) {
    .chat-container {
        width:100%;
    }
}
