.video {
    width: 60%;
    height: 100%;
    position: relative;

    &-client {
        position: relative;
        width: 95%;
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
        justify-content: center;
        box-shadow: 5px 5px 5px #B8B8B8;
        display: flex;

        &-noCam {
            box-shadow: none;

            .video-before {
                width: 100%;
                background-color: #151515;

                &-icon {
                    background-color: #363636;
                    margin: auto;
                }
            }
        }

        video {
            object-fit: cover;
            margin-left: auto;
            margin-right: auto;
            height: 100%;
        }
    }

    &-before {
        background-color: #EFEFEF;
        position: relative;
        width: 95%;
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        &-info {
            text-align: center;
            position: absolute;
            top: 15px;
            left: 50%;
            transform: translateX(-50%);
        }

        &-conf {
            position: absolute;
            top: 15px;
            right: 15px;

            a {
                text-decoration: none;

                img {
                    filter: brightness(0.5);
                    width: 25px;
                }
            }
        }

        &-icon {
            width: 175px;
            height: 185px;
            background-color: #D9D9D9;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5rem;
            font-weight: 900;
            color: #ffff;
            margin: auto;
        }

        &-control {
            position: absolute;
            bottom: 50px;
            width: 90%;
            left: 5%;
            text-align: center;

            p, a {
                font-size: 18px;
                font-weight: 600;
                text-decoration: none;
                text-align: center;

                img {
                    border-radius: 50px;
                }
            }

            &-configuration {
                color: #9f9f9f;
                font-size: 14px !important;
                font-weight: 400 !important;
                margin-top: 20px !important;
            }
        }

        &-label {
            font: Bold 24px/22px Lato;
            letter-spacing: 0;
            color: #9F9F9F;
            opacity: 1;
            padding-top: 45px;

            &-info {
                text-align: right;
                font: Bold 14px/22px Lato;
                letter-spacing: 0;
                color: #9F9F9F;
                opacity: 1;
                padding-top: 15px;
            }
        }

        &-waiting {
            margin-top: 20px;
            font: Normal 16px/20px Lato;
            color: #9F9F9F;
        }
    }

    &-my {
        top: 25px;
        left: 25px;
        position: absolute;
        width: 150px;
        overflow: hidden;
        height: 150px;
        border-radius: 15px;
        opacity: 1;
        display: flex;
        justify-content: center;
        webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
        -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
        -o-transform: matrix(-1, 0, 0, 1, 0, 0);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        video {
            object-fit: cover;
        }
    }

    &-controls {
        display: flex;
        flex-direction: row;
        justify-content: center;

        div {
            align-self: center;
            padding: 0 12px;

            &:first-child {
                padding-left: 0;
                padding-right: 12px;
            }

            &:last-child {
                padding-right: 0;
                padding-left: 12px;
            }

            img {
                cursor: pointer;
            }
        }

        &-classic {
            position: absolute;
            bottom: 50px;
            left: 0px;
            width: 100%;
            z-index: 10;

            img {
                border-radius: 50px;
            }

            .camera-switch-container {
                position: relative;
                height: 55px;
                display: flex;
                align-items: center;

                .camera-switch-background {
                    display: block;
                }

                .camera-icon {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        &-resize {
            position: absolute;
            top: 25px;
            right: 25px;
            z-index: 10;
        }
    }
}

.toggle-camera {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.cameras-dialog-container {
    bottom: 210px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    max-width: 402px;
    width: 100%;
    border-radius: 17px;
    border: 1px solid #565656;
    background: rgba(51, 51, 51, 0.9);
    color: #FFF;
    padding: 16px 10px;

    .cameras-dialog-wrapper {
        color: #22C8CF;
        display: flex;
        gap: 22px;
        position: relative;
        border-radius: 17px;
        padding: 12px 0 12px 60px;
        cursor: pointer;

        img {
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            left: 30px;
            top: 50%;
        }

        &:hover {
            background: #4E4E4E;
        }
    }
}

.cameras-toggle-container {
    padding: 16px 24px;
    transform: translateX(-50%);
    position: absolute;
    max-width: 402px;
    width: 100%;
    height: 55px;
    bottom: 139px;
    left: 50%;
    background: rgba(51, 51, 51, 0.9);
    border-radius: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;

    .cameras-toggle-content-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 12px;
    }

    .cameras-toggle-arrow-wrapper {
        display: flex;
        align-items: center;

        img {
            cursor: pointer;
        }
    }
}

.camera-label {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1199px) {
    .video {
        margin-left: auto;
        width: 100vw;
        height: 90%;

        &-client {
            box-shadow: 2px 1px 1px;
            width: 100%;
            object-fit: cover;
            display: flex;
            justify-content: center;
            border-radius: 0;

            video {
                object-fit: cover;
                width: 100%;
            }
        }

        &-before {
            width: 100%;
            border-radius: 0;
        }
    }
}

@media (max-width: 667px) {
    .video {
        height: 89%;

        &-my {
            top: 20px;
            left: 20px;
        }

        &-controls {
            &-resize {
                display: none;
            }

            &-classic {
                bottom: 5px;

                img {
                    border-radius: 50px;
                }

                img:not(.camera-switch-background) {
                    width: 45px;
                }

                img.camera-switch-background {
                    height: 45px;
                    display: none !important;
                }

                .camera-switch-container, .voice-container, .video-controls-classic-endcall {
                    height: 45px;
                }

                .camera-switch-container {
                    display: block;
                }

                .camera-icon {
                    position: relative !important;
                    transform: none !important;
                    top: 0 !important;
                }

                &-endcall {
                    img {
                        width: 45px !important;
                    }
                }
            }
        }

        &-before {
            &-control {
                a {
                    img {
                        width: 60px;
                    }
                }
            }
        }
    }

    .cameras-toggle-container {
        bottom: 94px;
    }

    .cameras-dialog-container {
        bottom: 165px;
    }

    .toggle-camera {
        display: block;
    }
}

@media (max-height: 667px) {
    .video {
        &-before {
            &-icon {
                width: 100px;
                height: 100px;
                font-size: 3rem;
            }

            &-control {
                img {
                    width: 45px;
                }
            }
        }

        &-controls {
            z-index: 99999;

            &-classic {
                bottom: 20px;

                img {
                    border-radius: 50px;
                }
            }

            &-resize {
                top: 15px;
            }
        }
    }
}

@media (max-height: 640px) {
    .video {
        &-client {
            width: 100%;
            object-fit: cover;
            display: flex;
            justify-content: center;

            video {
                object-fit: cover;
            }
        }

        &-my {
            top: 10px;
            left: 10px;
            height: 80px;
            width: 80px;
            border-radius: 5px;
        }
    }
}