$danger: #dc3545;

.settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &-config {
        width:100%;
        display:flex;
        padding:0 25px;
        align-items: center;
        justify-content: center;
    }

    &-test {
        width:40%;
        margin: 0 5%;
        &-video {
            width:90%;
            margin-right: auto;
            margin-left: auto;
            border-radius: 25px;
            overflow:hidden;
                video {
                width:100%;
            }

            &-noCam {
                background-color: #363636;
                min-height:496px;
                display: flex;
                justify-content: center;
                align-items: center;
                div {
                    font-size: 50px;
                    font-weight: 500;
                    color: #F2F2FD;
                }
            }
        }

        &-volume-control {
            display: flex;
            align-items: center;
            width: 90%;
            margin-right: auto;
            margin-left: auto;

            p {
                background-color: #9f9f9f;
                border: 1px solid #9f9f9f;
                height: 25px;
                min-width: 25px;
                box-shadow: 0 0 0 5px #ffff inset;
                box-sizing: border-box;
                border-radius: 30px;
                float: left
            }

            p.success {
                background-color: #28a745;
                border-color: #28a745;
            }

            p.error {
                color: #dc3545;
            }

            span {
                padding: 0 5px;
                color: #9f9f9f;
                font-weight: 500;
            }

            span.success {
                color: #28a745;
            }

            span.error {
                color: #dc3545
            }
        }

    }

    &-field {
        width:40%;
        padding: 0 5%;

    }

    &-not-supported {
        text-align: center;
        color: $danger;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        padding: 0.75rem 1.25rem;
        border-radius: 0.25rem;
        margin-bottom: 25px;
        margin-top: -30px;

        a {
            color: $danger;
        }
    }

    &-header {
        margin-top: -50px;
        margin-bottom: 50px;
    }

    &-input {
        width: 100%;

        &-group {
            margin-bottom: 1rem;

            label {
                display: inline-block;
                margin-bottom: 0.5rem;
            }

            &-control {
                display: block;
                width: 100%;
                height: calc(1.5em + 0.75rem + 2px);
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
        }
    }

    &-btn {
        padding: 15px 25px !important;
        margin: 50px 15px 0 !important;
        border-radius: 30px !important;
    }

    &-before-permissions {
        text-align: center;
        margin: 0 15px;

        div {
            font-size: 20px;
            margin-bottom: 40px;
        }

        &-btn {
            padding: 15px 25px !important;
            border-radius: 30px !important;
        }

        &-link {
            color: #666;
            text-decoration: none;
        }
    }
}

@media (max-width: 1199px) {
    .settings {

        &-header {
            margin-bottom: 25px;
            margin-top:0;
        }

       &-config {
           flex-direction: column;
       }

        &-test {
            width:55%;
        }
    }
}

@media (max-width: 769px) {
    .settings {

        &-test {
            width: 70%;
        }

        &-field {
            margin-top:25px;
            width: 70%;
        }
    }
}

@media (max-width: 667px) {
    .settings {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        &-header {
            margin-top:25px;
        }

        &-test {
            width:80%;
        }

        &-field {
            width:80%;
        }

        &-input {
            width: 90%;
        }

        &-btn {
            margin-top:20px !important;
            padding: 10px 20px !important;
        }
    }
}

@media (max-width: 400px) {
    .settings {

        &-header {
            margin-top:0;
            margin-bottom: 15px;
        }

        &-test {
            width:100%;
        }

        &-field {
            width: 100%;
        }
        &-input {
            width:100%;
        }


    }
}