.googleBtn {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 0 30px #00000036;
  border-radius: 20px;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  color: #363636;
  cursor: pointer;

  .googleIcon {
    height: 25px;
    width: 25px;
  }

  .googleBtnTextWrapper {
    display: flex;
    gap: 12px;
  }
}