.stopwatch-counter {
    margin-bottom: 0 !important;
    font-size: 14px;
    font-weight: 600;
}

@media (max-width: 1199px) {
    .stopwatch-counter {
        position: absolute;
        top: 90%;
        left: 15px;
    }
}

@media (max-width: 667px) {
    .stopwatch-counter {
        display: none;
    }
}