.messages {
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ECECEC;
    border-radius: 19px;
    opacity: 1;

    &-video--width {
        height: 100%;
        width: 40%;
    }

    &-other--width {
        height: 100%;
        width: 60%;

        .messages-bar-input {
            width: 88%;
        }

    }

    &-fullscreen {
        height: 100%;
        width: 100%;

        .messages-bar-input {
            width: 92% !important;
        }
    }

    &-reverse {
        display: flex;
        flex-direction: row-reverse;
    }

    &-list {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 90%;
        padding-bottom: 25px;

        &-item {
            padding: 20px;

            &-container {
                display: flex;
                align-items: flex-start;

                &-body {
                    flex: 1 1;
                }
            }


            &-my {
                text-align: end;
            }

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                border-radius: 5px;
                background-color: #D9D9D9;

                p {
                    align-self: center;
                    font-size: 24px;
                    font-weight: 600;
                    color: #ffff;
                    margin: 0;
                }

                &-my {
                    background-color: #6347fb;
                }
            }

            &-label {
                font: Bold 14px/22px Lato;
                letter-spacing: 0;
                color: #9F9F9F;
                opacity: 1;
                margin-bottom: 10px;

                span {
                    font-size: 10px;
                }

                &-my {
                    text-align: end;
                    color: #6347fb;
                    margin-right: 10px;
                }
            }

            &-content {
                background: #EFEFEF 0% 0% no-repeat padding-box;
                border-radius: 0px 19px 19px 19px;
                opacity: 1;
                padding: 10px;
                width: fit-content;
                word-break: break-word;
                white-space: normal;

                &-my {
                    text-align: right;
                    border-radius: 19px 0px 19px 19px;
                    float: right;
                    background: #F2EFFF 0% 0% no-repeat padding-box;
                }
            }
        }
    }

    &-typing {
        font-size: 12px;
        font-weight: 600;
        position: absolute;
        bottom: 70px;
        margin-left: 85px;
    }

    &-bar {
        width: 100%;
        padding: 0 20px;
        position: absolute;
        bottom: 20px;

        &-container {
            height: 10%;
            position: relative
        }

        &-input {
            border: 1px solid #E3E3E3;
            border-radius: 12px;
            opacity: 1;
            height: 50px;
            width: 79%;
            display: block;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }

            &:disabled {
                background-color: #dadada;
            }
        }


        &-uploadBtn {
            margin-right: 15px;
            padding: 0 2px;
            border-radius: 5px;
            cursor: pointer;
            text-align: center;
            display: flex;

            &-disabled {
                filter: grayscale(1);
                pointer-events: none;
                user-select: none;
            }

            &-image {
                justify-content: center;
                width: 46px;
            }
        }

        &-progress {
            width: 60px;
            height: 50px;
            margin: 0 10px 0 0;
            border-radius: 15px;
            display: flex;
            align-items: center;

            p {
                margin-bottom: 0;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                padding: 5px;
            }

            &-in {
                background-color: #4e555b;
            }

            &-done {
                background-color: #1c7430;
            }
        }

        &-sendBtn {
            margin-left: 10px;
            cursor: pointer;
        }
    }
}

@media (max-width: 1199px) {

    .messages {
        height: 95%;

        &-video--width {
            display: none;
        }

        &-other--width {
            display: none;
        }

        &-fullscreen {
            height: 95%;
        }

        &-bar {
            .row {
                width: 99vw !important;
            }
        }
    }
}

@media (max-width: 667px) {

    .messages {
        height: 94%;
        border-radius: -0;

        &-typing {
            bottom: 50px
        }

        &-list-item {
            padding: 10px;

            &-icon {
                display: none;
            }

            &-content {
                padding: 5px 10px;
                font-size: 0.8rem;
            }
        }

        &-bar {
            bottom: 10px;

            &-uploadBtn {
                height: 40px;
                padding: 0 5px;
            }

            &-input {
                height: 40px;
            }

            &-sendBtn {
                height: 40px
            }
        }
    }


}


@media (max-height: 667px) {
    .messages {
        &-list {
            &-item {
                &-icon {
                    p {
                        font-size: 20px;
                        font-weight: 500;

                    }
                }

            }
        }
    }
}
