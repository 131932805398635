.waiting {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    background: #fff;
    font-family: Lato, serif;
    color: #16182A; 
    font-weight: 700;
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
}