.attachments {
    width: 40%;
    height: 100%;

    &-container {
        width: 95%;
        border: 1px solid #ECECEC;
        border-radius: 19px;
        height: 100%;
        padding: 0 50px;
        padding-top: 3rem !important;
        overflow: hidden;
    }

    &-title {
        text-align: center;
        font: Bold 24px/22px Lato;
        letter-spacing: 0;
        color: #16182A;
        opacity: 1;
        margin-bottom: 50px;
        padding-top: 3rem !important;

        &:before {
            content: "";
            width: 30%;
            background-color: #d1d1d1;
            display: block;
            height: 2px;
            padding-top: 0px;
            margin-bottom: -15px;
        }

        &:after {
            content: "";
            width: 30%;
            background-color: #d1d1d1;
            display: block;
            height: 2px;
            padding-top: 0px;
            margin-left: 70%;
            margin-top: -10px;
        }
    }

    &-list {
        &-container {
            height: 100%;
            overflow: hidden;
        }

        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        text-align: left;
        font: Bold 16px/22px Lato;
        letter-spacing: 0;
        color: #242839;
        opacity: 1;
        height: 80%;
        overflow-y: scroll;
        overflow-x: hidden;

        li {
            border: none;
            border-bottom: 1px solid #f8f8f8;
            padding-top: 40px;
            position: relative;
            display: block;
            background-color: #fff;
            p {
                margin-bottom: 0;
            }
            span {
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 1199px) {
    .attachments {
        width: 100%;
        height: 90%;

        &-container {
            width: 100vw;
        }
    }
}

@media (max-width: 667px) {
    .attachments {
        height: 89%;

        &-container {
            border-radius: 0;
        }
    }
}